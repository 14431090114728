const updateDate = () => {
    let countDownDate = new Date("2023-01-06T21:00:00");
    let element = document.getElementById("countdown-text");
    let now = new Date();

    let distance = countDownDate - now;
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    element.innerHTML = `${days}d ${hours}h ${minutes}m ${seconds}s`;
};

window.addEventListener('DOMContentLoaded', () => {
    updateDate();
    const interval = setInterval(updateDate, 1000);
});
